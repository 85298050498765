@import "nlib/config";

.uploadedByCell {
  position: relative;
  .avatar {
    &.email {
      background-color: lighten($uiMediumDarkColor, 15) !important;
      svg {
        font-size: 1.4rem;
      }
    }
  }
  .tooltip {
    max-width: 24rem;
    &.email {
      min-width: 16rem;
    }
    > div {
      margin-bottom: $contentDefaultIndent;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      &.name,
      &.date {
        white-space: nowrap;
      }
      &.subject,
      &.text {
        font-weight: normal;
        line-height: 1.1;
      }
      &.name,
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
