@import "nlib/config";

.commentsBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  overscroll-behavior: contain;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $uiBorderColor;
    .title {
      flex: auto;
      padding-left: $contentDefaultIndent * 1.5;
      font-size: $uiDesktopFontSize;
      font-weight: 500;
    }
    .icon {
      display: flex;
      padding: $contentDefaultIndent * 1.5;
      color: $uiMediumDarkColor;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        color: $uiDarkColor;
      }
    }
  }
  .contentWrap {
    display: flex;
    position: relative;
    flex: auto;
    flex-direction: column;
    height: 12rem;
    margin-bottom: $contentDefaultIndent;
    overflow: hidden;
    .content {
      flex: auto;
      width: 100%;
      padding: $contentDefaultIndent;
      overflow: auto;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 16px;
        background-image: linear-gradient(transparent, $uiWhiteColor);
      }
      .comments {
        display: flex;
        flex-direction: column-reverse;
      }
      .preloader {
        height: 100%;
        font-size: 3rem;
      }
    }
    .emptyState {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      svg {
        opacity: 0.15;
        font-size: 5rem;
      }
    }
  }
  &.compact {
    .contentWrap {
      overflow: auto;
      .content {
        max-height: 175px;
      }
    }
  }
  .addCommentWrap {
    padding: 0 $contentDefaultIndent $contentDefaultIndent;
  }
}
