@import "nlib/config";

.createFolderForm {
  display: flex;
  .inputWrapper {
    position: relative;
    flex: 1;
    .tooltip {
      color: $uiNegativeColor;
      white-space: nowrap;
    }
  }
  .input {
    margin-right: $contentDefaultIndent;
  }
  .button {
    margin-left: $contentDefaultIndent;
  }
}
