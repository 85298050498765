@import "nlib/config";

.vaultPage {
  .pageHeader {
    margin-bottom: 0;
  }
  .foldersRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $contentDefaultIndent;
    .button {
      position: relative;
      margin-right: $contentDefaultIndent;
      margin-bottom: $contentDefaultIndent;
      svg {
        font-size: 1rem;
      }
      .folderName {
        margin-left: 0.3em;
      }
      .folderFilesCount {
        margin-left: 0.6em;
      }
      .delete {
        display: flex;
        position: absolute;
        z-index: 1050;
        top: 0;
        right: 0;
        padding: $contentDefaultIndent / 2;
        transform: translate(50%, -50%);
        border-radius: 50%;
        opacity: 0;
        background-color: $uiNegativeColor;
        color: $uiWhiteColor;
        font-size: 0.75rem;
        svg {
          font-size: 1.2em;
        }
      }
      &:hover .delete {
        opacity: 1;
        filter: grayscale(0.25);
      }
      &:hover .delete:hover {
        filter: none;
      }
    }
  }
  .emptyState {
    display: flex;
    flex: auto;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background: $uiWhiteColor;
    .buttons {
      display: flex;
      button + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
