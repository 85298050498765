@import "nlib/config";

.card {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: $contentDefaultIndent * 2;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 0 0 20px rgba(57, 57, 68, 0.1);
  font-size: 0.875rem;
}
