@import "nlib/config";
@import "animations";

.headerContent {
  display: flex;
  .createFolderForm {
    width: 400px;
  }
  .button + .button {
    margin-left: $contentDefaultIndent;
  }
  [data-loading] svg {
    animation: rotate 2s linear infinite;
  }
}
