@import "nlib/config";

.comment {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: $contentDefaultIndent;
  padding-right: 1rem;
  &.notRead {
    animation: newComment 8s ease-in-out forwards;
    border-radius: $uiBorderRadius / 2;
  }
  .avatar {
    margin-right: 6px;
    font-size: 0.625rem;
    cursor: pointer;
    &.hidden {
      visibility: hidden;
    }
  }
  .text {
    position: relative;
    max-width: 100%;
    height: fit-content;
    padding: $contentDefaultIndent;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
    font-size: 0.6875rem;
    line-height: 1rem;
    word-break: break-word;
    white-space: pre-wrap;
    .mention {
      color: $uiHighlightColor;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .note {
      display: flex;
      justify-content: flex-end;
      margin-top: -3px;
      margin-right: -3px;
    }
  }
  &.currentUser {
    flex-direction: row-reverse;
    padding-right: 0;
    padding-left: 1rem;
    .avatar {
      margin-right: 0;
      margin-left: $contentDefaultIndent;
    }
    .text {
      background-color: rgba($uiPrimaryColor, 0.05);
    }
  }
  &.typeNote {
    .text {
      background-color: lighten($uiWarningColor, 35);
    }
  }
}
[data-mobile] {
  .comment {
    .text {
      position: relative;
      max-width: 100%;
      height: fit-content;
      margin-bottom: $contentDefaultIndent / 4;
      padding: $contentDefaultIndent / 2 $contentDefaultIndent;
      border-radius: $uiBorderRadius;
      font-size: 0.875rem;
      line-height: 1.25;
      word-break: break-word;
      white-space: pre-wrap;
      .mention {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .avatar {
      margin-right: $contentDefaultIndent;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 0.75rem;
    }
    &.currentUser {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 3rem;
      .avatar {
        margin-right: 0;
        margin-left: $contentDefaultIndent;
      }
    }
  }
}

@keyframes newComment {
  0% {
    background-color: lighten($uiPrimaryColor, 50);
    box-shadow: 0 0 0 4px lighten($uiPrimaryColor, 50);
  }
}
