@import "nlib/config";

.dropArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  padding: $contentDefaultIndent;
  color: $uiDarkColor;
  text-align: center;
  .accepted {
    display: flex;
    color: $uiPositiveColor;
    word-break: break-word;
    svg {
      margin-right: 0.3em;
      font-size: 1.2rem;
    }
  }
  .text {
    font-size: 0.875rem;
    svg {
      font-size: 1.25rem;
    }
  }
  .formats {
    margin-top: $contentDefaultIndent;
    font-size: 0.75rem;
    .extensions {
      color: $uiFontColor;
    }
  }
}
