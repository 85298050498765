@import "nlib/config";

.bulkActions {
  position: relative;
  position: sticky;
  z-index: 4;
  top: $contentDefaultIndent * 2;
  align-items: flex-start !important;
  width: 100%;
  margin-bottom: $contentDefaultIndent * 2;
  padding-right: $contentDefaultIndent * 2;
  padding-left: $contentDefaultIndent * 2;
  border: 1px solid $uiPrimaryColor;
  .text {
    display: flex;
    align-items: center;
    height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .inputs {
    display: flex;
    flex: auto;
    margin-right: auto;
    margin-left: $contentDefaultIndent * 3;
    padding-right: $contentDefaultIndent * 3;
    .input {
      display: flex;
      flex: auto;
    }
  }
  .tagsInput {
    flex: auto;
    min-width: 12rem;
    max-width: 22rem;
  }
  .buttons {
    display: flex;
    margin-left: auto;
    margin-left: 1%;
  }
  .button {
    margin-left: $contentDefaultIndent;
  }
  .createFolderForm {
    flex: auto;
    max-width: 420px;
    margin-right: $contentDefaultIndent * 3;
  }
  .changeFolderSelector {
    flex: auto;
    max-width: 220px;
    margin-right: $contentDefaultIndent * 3;
  }
}
