@import "nlib/config";

.dateRangePickerExtended {
  width: 100%;
  .input {
    min-width: 218px;
  }
  .clear {
    display: flex;
    position: absolute;
    top: 50%;
    right: $contentDefaultIndent;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0, -50%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
.dateRangePickerDropDown {
  .extension {
    padding: $contentDefaultIndent * 2;
    border-left: 1px solid $uiBorderColor;
    .button {
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0.375rem;
      padding-right: $contentDefaultIndent;
      padding-left: $contentDefaultIndent;
    }
  }
  .additional {
    display: none;
  }
}
