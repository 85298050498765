@import "nlib/config";

.vaultTable {
  flex: auto;
  white-space: nowrap;
  .table {
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    box-shadow: inset 0 0 0 1px $uiBorderColor;
  }
  [data-table-cell] {
    padding-right: 0.6%;
    padding-left: 0.6%;
  }
  .row {
    cursor: pointer;
    &.notRead {
      background-color: lighten($uiHighlightColor, 54%);
    }
  }
  .checkboxCell {
    flex: none;
    width: 31px;
    padding-right: 0;
  }
  .nameCell {
    flex: 30;
    width: 30px;
    min-width: 100px;
    .icon {
      margin-right: 0.3em;
      font-size: 1.25rem;
    }
  }
  .descriptionCell {
    flex: 30;
    width: 30px;
    min-width: 80px;
  }
  .tagsCell {
    flex: 20;
    width: 20px;
    min-width: 80px;
  }
  .typeCell {
    flex: 13;
    width: 13px;
    min-width: 84px;
  }
  .createdCell {
    flex: 12;
    width: 12px;
    min-width: 118px;
  }
  .fromDateCell {
    flex: 10;
    width: 10px;
    min-width: 82px;
  }
  .toDateCell {
    flex: 10;
    width: 10px;
    min-width: 82px;
  }
  .uploadedByCell {
    flex: 6;
    justify-content: center;
    width: 6px;
    min-width: 46px;
  }
  .commentsCell {
    flex: 8;
    width: 8px;
    min-width: 54px;
    padding-right: 0;
  }
  .actionsCell {
    flex: none;
    width: 112px;
    padding-top: $contentDefaultIndent / 2;
    padding-right: $contentDefaultIndent;
    padding-bottom: $contentDefaultIndent / 2;
    padding-left: $contentDefaultIndent * 2;
    .button {
      padding: 0 $contentDefaultIndent;
      svg {
        font-size: 1rem;
      }
    }
  }
  .selected {
    background-color: rgba($uiPrimaryColor, 0.05);
  }
  .overflow {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination {
      margin-right: $contentDefaultIndent * 2;
    }
    .pagination,
    .pageSize {
      margin-top: $contentDefaultIndent * 2;
    }
  }
}
