@import "nlib/config";

.sidebar {
  .preloader {
    flex: 1;
  }
  .fileInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $contentDefaultIndent;
    text-align: center;
    cursor: pointer;
    .icon svg {
      font-size: 2rem;
    }
    .name {
      margin-left: $contentDefaultIndent / 2;
      overflow: hidden;
      font-size: 0.9rem;
      text-decoration: underline;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      .name {
        color: $uiPrimaryColor;
      }
    }
  }
  .label {
    margin-top: $contentDefaultIndent * 2;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
  }
  .email {
    display: flex;
    align-items: center;
    margin-top: $contentDefaultIndent / 2;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      svg {
        color: $uiPrimaryColor;
      }
    }
    >span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > svg {
      font-size: 1.2em;
    }
  }
  .row {
    display: flex;
    margin-top: $contentDefaultIndent * 2;
    .col {
      flex: 1;
      max-width: 100%;
      + .col {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
